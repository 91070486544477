import React, { Component } from "react";
import axios from "axios";
import ReactPaginate from 'react-paginate';
import { Container, Row, Col } from "react-bootstrap";


class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBlogs: {
        results: []
      },
      allBlogs: {},
      activeBlog: {}
    }
  }

  loadBlogs = (page, firstLoad = false) => {
    return new Promise((resolve, reject) => {
      axios
      .get(`${process.env.REACT_APP_API_ROOT}/read?table=blogs&type=getListPaginate&indexName=BlogGsi&partitionKey=generic_partition_key&paritionKeyType=S&partitionValue=Blog&scanIndexForward=false`, {
        headers: {
          'X-Api-Key': `${process.env.REACT_APP_API_KEY}`
        }
       })
      .then(res => {
        this.setState({
          allBlogs: res.data.results,
          activeBlogs: {results: res.data.results[page]},
          allBlogsCount: res.data.totalPages
        });

        if (firstLoad) {
          this.setState({
            activeBlog: this.state.activeBlogs.results[0]
          });
        }        
        resolve("Success");
      })
      .catch(err => {
        console.error(err);
        reject("Failure");
      })
    });
  };

  componentDidMount = async () => {
    await this.loadBlogs(1, true);
  }

  handlePageClick = async paginationData => {
    const page = paginationData.selected + 1;
    this.setState({
      activeBlogs: {results: this.state.allBlogs[page]},
    });
  }

  buildPaginationMenu = () => {
    return (
      <Container>
        <div className="pagination-items">
        {this.state.activeBlogs.results.map(blog =>
        { 
          const activeBlogId = this.state.activeBlog.id !== undefined ? this.state.activeBlog.id["S"] : "";
          return (
            <Row key={`row-${blog.id["S"]}`}>
              <Col key={`col-${blog.id["S"]}`}>
                <p 
                  key={`para-${blog.id["S"]}`}
                  className={`blog-pagination-title${activeBlogId === blog.id["S"] ? '-active' : ''}`} 
                  onClick={() => {
                    this.setState({activeBlog: blog});
                  }}
                >
                    {`${blog.title['S']} - ${new Date(parseInt(blog.created_at['N'])).toDateString()}`}
                </p>
              </Col>
            </Row>
          );
        })}
        </div>
        <Col id="react-paginate">
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.allBlogsCount}
            marginPagesDisplayed={5}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active-pagination'}
          />
        </Col>
      </Container>
    );
  };

  render() {
    if (this.state.activeBlog) {
      return (
        <div className="blog-container"> 
          <Container className="blog-content">
            <Row>
              <Col>
                <h1>{this.state.activeBlog.title !== undefined ? this.state.activeBlog.title["S"] : "" }</h1>
                <p id="active-blog-text">{this.state.activeBlog.content !== undefined ? this.state.activeBlog.content['S'] : ""}</p>
              </Col>
              <Col>
                {this.buildPaginationMenu()}
              </Col>
            </Row>
          </Container>
          
        </div>
      );
    } else {
      return (
        <div className="blog-container"> 
          <Container className="blog-content">
            <Row>
              <Col>
                <p id="no-blogs-text">No blogs currently posted</p>
              </Col>
            </Row>
          </Container>
          
        </div>
      );
    }
    
    }
};
   
export default Blog;