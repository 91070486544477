import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

class Impact extends Component {
    render() {
        return (
        <div>
            <Container className="about-first-section">
                <Row>
                    <Col>
                        <p className="standard-header">IMPACT WORK AND FUNDING</p>
                        <p>
                            I am currently collaborating with colleagues and also external stakeholders in the UK, Northern Ireland and the Republic of Ireland who participated or observed the Northern Irish conflict to ensure that my research achieves a significant impact beyond academia, particularly in relation to debates about dealing with conflict-legacy on the island of Ireland.<br/><br/>
                            <strong>I have received funding for impact and research from the Political Studies Association UK, Economic and Social Research Council, the Irish Research Council Postdoctoral Government of Ireland Fellowship, the Scouloudi Foundation, the Irish Department of Foreign Affairs and Trade's Reconciliation Fund, Cardiff University and King’s College London.</strong><br/><br/>
                            Currently, I am investigating the role of the Irish state in dealing with conflict legacy on the island of Ireland. I engage with victims/survivors, political parties north and south, alongside British and Irish governments and security forces on this topic.<br/><br/>
                            Impact work has included presenting my research to the Oireachtas Joint Committee on the Implementation of the Good Friday Agreement in June 2019: <a href="https://www.oireachtas.ie/en/debates/debate/joint_committee_on_the_implementation_of_the_good_friday_agreement/2019-06-20/3/" target="_blank" rel="noopener noreferrer"><u>Read more here.</u></a><br/><br/>
                            I am happy to be contacted about impact work. 
                        </p>
                    </Col>
                </Row>
            </Container>

        </div>

        );
    }
  }
   
  export default Impact;