import React, { Component } from "react";
import axios from "axios";
import env from "react-dotenv";
import { Container, Row, Col } from "react-bootstrap";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workItems: []
        }
    }

    buildWorkItem = (title, summary, link, number) => {
        let classText = number % 2 === 0 ? "work-item-blue" : "work-item-darker-blue";

        return (
            
            <Row>
                <Col className={classText}>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                    <p className="small-header">{title}</p>
                    <p className="small-text">{summary}</p>
                    </a>
                </Col>
            </Row>
            
        );
    }

    setWorkItems = items => {
        let _workItems = [];
        
        let count = 0;
        items.forEach(item => {
            _workItems.push(
                this.buildWorkItem(item.title["S"], item.summary["S"], item.link["S"], count)
            );

            count += 1;
        });

        this.setState({
            workItems: _workItems
        })
    }

    loadWorkItems = () => {
        return new Promise((resolve, reject) => {
            axios
            .get(`${process.env.REACT_APP_API_ROOT}/read?table=works&type=getList`, {
              headers: {
                'X-Api-Key': `${process.env.REACT_APP_API_KEY}`
              }
            })
            .then(res => {
              console.log(res)
                this.setWorkItems(res.data.Items);
                resolve("Success");
            })
            .catch(err => {
                reject("Failure");
            })
        });
    }

    componentDidMount = () => {
        this.loadWorkItems();
    }

    render() {
        return (
        <div>
            <Container className="about-first-section">
                <Row>
                    <Col>
                        <p className="standard-header">WELCOME</p>
                        <p>
                            I am a Senior Lecturer in British & Irish Politics/Contemporary History at Cardiff University in Wales. My particular specialism is about the conflict and peace process in and about Northern Ireland.<br/><br/>

                            My book the Intelligence War Against the IRA was published with Cambridge University Press in paperback and hardback in 2020. <a href="https://www.cardiff.ac.uk/news/view/2576640-prestigious-irish-book-prize-awarded-to-cardiff-academic" target="_blank" rel="noopener noreferrer"><u>The book won the Brian Farrell Book Prize by the Political Studies Association of Ireland in 2021.</u></a> It was also shortlisted for the Royal Historical Society’s Whitfield Prize in 2021.<br/><br/>

                            The book argues that despite some successes, British intelligence did not force the IRA into peace for four principle reasons.<br/><br/>
                            <strong>I primarily research on:</strong><br/><br/>
                            - Dealing with conflict legacy across the island of Ireland <br/>
                            - Various aspects of the Northern Ireland conflict <br/>
                            - Irish Republicanism <br/>
                            - Northern Irish/Irish politics and the peace process/Brexit <br/>
                            - Irish unification debates  <br/>
                            - Intelligence conflicts including in Northern Ireland against the IRA.  <br/>
                            - The use of informers/agents/special forces and intelligence in Northern Ireland since 1969.  <br/><br/>
                            

                        </p>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className="about-second-section" style={{paddingTop: "2%"}}>
                    <Col>
                        <Row className="image-text-row">
                            <Col className="thomas-image-text text-center align-items-center">
                                <p className="standard-header">Media Experience</p><br/><br/>
                                I welcome all media enquiries and am very willing to assist media work. My research has been shared and reviewed in various media outlets including BBC News, RTE and more. Examples include:<br/><br/>
                                - Radio discussion about my Intelligence War Against the IRA book on RTE History Hour (December 2020). <a class="text-white" href="https://www.rte.ie/radio/radio1/the-history-show/programmes/2020/1206/1182732-the-history-show-sunday-6-december-2020/" target="_blank" rel="noopener noreferrer"><u>Listen here.</u></a><br/>
                                - 'British intelligence did not force the IRA into peace', Irish Times, 12 June 2020. <a class="text-white" href="https://www.irishtimes.com/culture/books/british-intelligence-did-not-force-ira-to-make-peace-1.4274630" target="_blank" rel="noopener noreferrer"><u>Read here.</u></a><br/>
                                - 'Did British intelligence force the IRA into peace?', RTÉ Brainstorm, 12 February 2020. <a class="text-white" href="https://www.rte.ie/brainstorm/2020/0211/1114632-did-british-intelligence-force-the-ira-into-peace/" target="_blank" rel="noopener noreferrer"><u>Read here.</u></a><br/>
                                - 'Irish Government urged to investigate Troubles Killings' in the Republic', Belfast Telegraph, 20 June 2019. <a class="text-white" href="https://www.belfasttelegraph.co.uk/news/northern-ireland/irish-government-urged-to-investigate-troubles-killings-in-republic-38238642.html" target="_blank" rel="noopener noreferrer"><u>Read here.</u></a><br/><br/>
                                I also presented my research to the Oireachtas (Irish Parliamentary) Joint Committee on the Implementation of the Good Friday Agreement in 2019. <a class="text-white" href="https://www.oireachtas.ie/en/debates/debate/joint_committee_on_the_implementation_of_the_good_friday_agreement/2019-06-20/3/" target="_blank" rel="noopener noreferrer"><u>Read more here.</u></a>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="about-third-section">
                <p className="standard-header">My List of Publications Includes</p><br/><br/>
                {
                    this.state.workItems.map(workItem => workItem)
                }
            
            </Container>

        </div>

        );
    }
  }
   
  export default Home;