import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

class Teaching extends Component {
    render() {
        return (
        <div>
            <Container className="about-first-section">
                <Row>
                    <Col>
                        <p className="standard-header">TEACHING AT CARDIFF UNIVERSITY POLITICS/IR DEPARTMENT SCHOOL OF LAW AND POLITICS</p>
                        <p>
                            <strong>Undergraduate level</strong><br/><br/>
                            - First-year undergraduate, 'Introduction to Government study skills'<br/>
                            - Final-year undergraduate, 'Bombs, Bullets and Ballot Boxes: The Northern Ireland Conflict'<br/><br/>
                            <strong>Masters</strong><br/><br/>
                            - Conflict and Peace: Northern Ireland, 1969 to 2020 (IR masters students)<br/>
                            - Politics in and about Northern Ireland since 1998 (with Dr Jonathan Kirkup) (Politics and Public Policy and Welsh Politics students)<br/><br/>

                            I have supervised various undergraduate and masters students for their dissertations.<br/><br/>
                            <strong>PhD</strong><br/><br/>
                            I supervise three PhD students who research locally raised security forces during the Northern Ireland conflict, regional conflict dynamics, and Northern Ireland Assembly and Irish Government relations since 1998.<br/><br/>
                        </p>

                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className="about-second-section" style={{paddingTop: "2%"}}>
                    <Col>
                        <Row className="image-text-row">
                            <Col className="thomas-image-text text-center align-items-center">
                                <p className="standard-header">EDUCATION AND QUALIFICATIONS</p><br/><br/>
                                2020 - Promoted to Senior Lecturer.<br/><br/>

                                2019- Higher Education Academy, Fellow.<br/><br/>

                                2015 – History PhD, King’s College London.<br/><br/>

                                2011 – Master of Research in Modern History (Distinction), King’s College London.<br/><br/>

                                2010 – BA (honours) History (First-class), King’s College London<br/><br/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="about-third-section">
                        <p className="standard-header">TEACHING AT CARDIFF UNIVERSITY POLITICS/IR DEPARTMENT SCHOOL OF LAW AND POLITICS</p>
                        <p>
                            <strong>Undergraduate level</strong><br/><br/>
                            - First-year undergraduate, 'Introduction to Government study skills'<br/>
                            - Final-year undergraduate, 'Bombs, Bullets and Ballot Boxes: The Northern Ireland Conflict'<br/><br/>
                            <strong>Masters</strong><br/><br/>
                            - Conflict and Peace: Northern Ireland, 1969 to 2020 (IR masters students)<br/>
                            - Politics in and about Northern Ireland since 1998 (with Dr Jonathan Kirkup) (Politics and Public Policy and Welsh Politics students)<br/><br/>

                            I have supervised various undergraduate and masters students for their dissertations.<br/><br/>
                            <strong>PhD</strong><br/><br/>
                            I supervise three PhD students who research locally raised security forces during the Northern Ireland conflict, regional conflict dynamics, and Northern Ireland Assembly and Irish Government relations since 1998.<br/><br/>
                        </p>
            </Container>
            <Container fluid>
                <Row className="about-second-section" style={{paddingTop: "2%"}}>
                    <Col>
                        <Row className="image-text-row">
                            <Col className="thomas-image-text text-center align-items-center">
                                <p className="standard-header">PROFESSIONAL MEMBERSHIPS</p><br/><br/>
                                Member of the Political Studies Association Ireland.<br/><br/>
                                Member of the Political Studies Association.<br/><br/>
                                Member of the European Consortium of Political Research Political Violence Section.<br/><br/>
                                Member of the British International Studies Association.<br/><br/>
                                Member of the Secrecy and Ignorance Network (SPIN) with GW4 partner institutions.<br/><br/>
                                <strong>Academic Positions</strong><br/><br/>
                                I was an Irish Research Council Government of Ireland Postdoctoral Fellow until September 2017 at the National University of Ireland in Galway, Republic of Ireland. In 2016, I worked for Cardiff University and taught a third-year module on the Northern Ireland conflict. I was awarded my History PhD at King’s College London in September 2015 under Professor Ian McBride (now of Oxford University).
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="about-first-section">
                <p className="standard-header">HONOURS AND AWARDS</p><br/><br/>
                <Row>
                    <Col>
                    2021 - Scouloudi & Institute of Historical Research Grant for Research<br/><br/>
                    2021 - My book Intelligence War Against the IRA has been shortlisted for the Royal Historical Society’s Whitfield Prize.<br/><br/>
                    2021 - Certification of commendation from The Learned Society of Wales as part of their Dillwyn Medal Award Panel for Early Career Researchers, May 2021.<br/><br/>
                    2020 - Political Studies Association UK Research and Development Funding (with Dr Kirkup and Dr Fahey).<br/><br/>
                    2019 - Irish Department of Foreign Affairs Reconciliation grant for impact research.<br/><br/>
                    2019 - Irish Department of Foreign Affairs Reconciliation grant for the Irish Historians in Britain Conference (2021).<br/><br/>
                    2016-2018 – Economic and Social Research Council impact initiator and accelerator account (with Dr Huw Bennett).<br/><br/>
                    2016-17 – Irish Research Council Government of Ireland Postdoctoral Fellow at the National University of Ireland Galway, Republic of Ireland.<br/><br/>
                    2016 – Scouloudi historical research award, London.<br/><br/>
                    2014 - Political Studies Association Ireland postgraduate bursary award to present at their annual conference in Galway, Ireland.<br/><br/>
                    2013 – King’s College London continuation scholarship for PhD studies, London.<br/><br/>
                    2011 – The Worshipful Company of Bowyer’s best MA dissertation prize on a military historical theme, King’s College London.<br/><br/>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className="about-second-section" style={{paddingTop: "2%"}}>
                    <Col>
                        <Row className="image-text-row">
                            <Col className="thomas-image-text text-center align-items-center">
                                <p className="standard-header">SPEAKING ENGAGEMENTS</p><br/><br/>
                                Select conference and seminar papers presented at: <br/><br/>

                                - Irish Conference of Historians, Galway University (online), 2021.<br/><br/>
                                - The UK in a Changing Europe early careers researcher event, Cardiff University, 2020.<br/><br/>
                                - Divided Societies conference, Inter-city University, Dubrovnik, 2017 and 2019.<br/><br/>
                                - Centre for Intelligence and International Security conference, Gregynog, November 2018.<br/><br/>
                                - Violence Studies conference, Oxford University, November 2018.<br/><br/>
                                - British International Studies Association annual conference, Bath, June 2018.<br/><br/>
                                - The Political Studies Association UK annual conferences, 2018 and 2020.<br/><br/>
                                - The European Consortium Political Research annual conferences, 2017 and 2020.<br/><br/>
                                - Political Studies Association Ireland annual conferences, 2014, 2016, 2018, 2020.<br/><br/>
                                - The Irish historians in Britain annual conferences, Edinburgh, Newcastle, 2014 and 2018.<br/><br/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="about-first-section">
                <p className="standard-header">IMPACT ACTIVITIES</p><br/><br/>
                <Row>
                    <Col>
                    August 2019 to present – Awarded by Irish DFAT Reconciliation fund to support impact work with Irish/UK politicians and victims/survivor groups on dealing with Troubles legacy.<br/><br/>
                    June 2019 – Presented research on the Irish state and dealing with Northern Ireland conflict legacy to the Oireachtas Joint Committee on Implementing the Good Friday Agreement.<br/><br/>
                    November 2018 - Awarded ESRC Festival of Social Sciences funding to host two panel debates at Cardiff University on ‘Why did peace emerge in Northern Ireland?’ with guest speakers and the public.<br/><br/>
                    2016-2018 - Awarded two ESRC impact account awards with Dr Bennett for the impact study on ‘Dealing with Northern Ireland’s Difficult Past’ working with various governments, victims/survivors groups, former political prisoners and politicians across the UK and Irish isles.<br/><br/>
                    Co-organised with Professor Ó Dochartaigh the ‘Dealing with conflict legacy in Ireland’ event in Dublin, September 2018.
                    </Col>
                </Row>
            </Container>

        </div>

        );
    }
  }
   
  export default Teaching;