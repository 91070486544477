import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import emailLogo from "../images/email.png";
import twitterLogo from "../images/twitter.png";
import env from "react-dotenv";


class Footer extends React.Component {
    render() { 
        return (
            <Container className="footer-container">
                <Row>
                    <Col className="contact-me-text">
                        Contact Me
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <a href="mailto:LeahyT1@cardiff.ac.uk" target="_blank">
                                            <img src={emailLogo} alt="Email" className="contact-logo-email image-responsive"></img>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <a href="https://twitter.com/thomasdmleahy?lang=en" target="_blank">
                                            <img src={twitterLogo} alt="Twitter" className="contact-logo-twitter image-responsive"></img>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        );
    }
}

export default Footer;
