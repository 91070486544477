import './App.css';

import { HashRouter, Route } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react'

import Blog from "./pages/Blog";
import Videos from "./pages/Videos";
import About from "./pages/About";
import Teaching from "./pages/Teaching";
import Impact from "./pages/Impact";
import MainNav from "./components/MainNav";
import Footer from "./components/Footer";


function App() {
  const handleOk = () => setShow(false);
  const [show, setShow] = useState(true);
  return (
    <div className="App">
      
    <HashRouter>
      <MainNav/>
      <div className="content">
        <Route exact path="/" component={About}/>
        <Route path="/teaching" component={Teaching}/>
        <Route path="/impact" component={Impact}/>
        <Route path="/blog" component={Blog}/>
        <Route path="/videos" component={Videos}/>
      </div>
    </HashRouter>
    <div className="footer">
      <Footer/>
    </div>

    <Modal show={show} onHide={handleOk} centered>
      <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              This site does not track or retain any of your data, or provide you with any cookies.
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={handleOk}>
              Ok!
          </Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
}

export default App;
