import axios from "axios";
import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import env from "react-dotenv";


class Videos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainVideo: {},
            videoGalleryRows: [],
            videos: []
        }
    }

    getVideoDisplay = (src, title, iframeClass) => {
        return (
            <Col>
                <iframe
                    src={src}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title={title}
                    className={iframeClass}
                />
                <p key={title} className="video-title">{title}</p>
            </Col>
        );
    }

    setVideos(videos) {
        let _videoGalleryRows = [];
        let videoGalleryColumns = [];

        let count = 0;
        videos.forEach(video =>{
            if (video.is_primary["BOOL"]) {
                this.setState({mainVideo: video})
            } else {
                if (count === 4) {
                    _videoGalleryRows.push(videoGalleryColumns);
                    videoGalleryColumns = [];
                    count = 0;
                }

                videoGalleryColumns[count] = this.getVideoDisplay(video.url["S"], video.title["S"], "thumbnail");
                count += 1;
            }
        });

        // Push any remaining videos into the rows list
        if (videoGalleryColumns !== []) {
            _videoGalleryRows.push(videoGalleryColumns);
        }

        this.setState({
            videoGalleryRows: _videoGalleryRows,
        });
    }

    loadVideos = () => {
        return new Promise((resolve, reject) => {
            axios
            .get(`${process.env.REACT_APP_API_ROOT}/read?table=videos&type=getList`, {
              headers: {
                'X-Api-Key': `${process.env.REACT_APP_API_KEY}`
              }
            })
            .then(res => {
              console.log(res)
                this.setVideos(res.data.Items);
                resolve("Success");
            })
            .catch(err => {
                reject("Failure");
            })
        });
    }

    componentDidMount = async () => {
        await this.loadVideos();
    }

    render() { 
      const videoGalleryHtml = this.state.videoGalleryRows <= 1 ? <div/> :
        <Container className="video-gallery" fluid>
        {
            this.state.videoGalleryRows.map(row => {
                return (
                    <Row>
                        {row.map(col => col)}
                    </Row>
                );
            })
        }
        </Container>;

        return (
            <div className="video-container">
                <Container className="main-video-container">
                    <Row>
                        <Col className="align-items-center">
                            <p className="standard-header">
                              {this.state.mainVideo.url ? "LATEST VIDEO" : "NO VIDEO CURRENTLY POSTED"}
                            </p>
                        </Col>
                        

                    </Row>
                    {
                        this.state.mainVideo.url ? this.getVideoDisplay(this.state.mainVideo.url["S"], this.state.mainVideo.title["S"], "mainVideo") : <div></div>
                    }                    
                </Container>
                
                {videoGalleryHtml}
                    
            </div>
        );
    }
}

export default Videos;
