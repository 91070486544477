import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from "react-bootstrap/Nav";

import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";


class MainNav extends React.Component {
    render () { return (
        <div className="top-div justify-content-center">
            <span className="thomas-header">
                DR. THOMAS LEAHY
            </span>

            <Navbar className="justify-content-center">
                <Nav.Link activeStyle={{color: 'grey', backgroundColor: '#E2E2E2'}} exact className="main-nav" as={NavLink} to="/">About</Nav.Link>
                <Nav.Link activeStyle={{color: 'grey', backgroundColor: '#E2E2E2'}} className="main-nav" as={NavLink} to="/impact">Impact & Funding</Nav.Link>
                <Nav.Link activeStyle={{color: 'grey', backgroundColor: '#E2E2E2'}} className="main-nav" as={NavLink} to="/teaching">Teaching & Qualifications</Nav.Link>
                <Nav.Link activeStyle={{color: 'grey', backgroundColor: '#E2E2E2'}} className="main-nav" as={NavLink} to="/blog">Blog</Nav.Link>
                <Nav.Link activeStyle={{color: 'grey', backgroundColor: '#E2E2E2'}} className="main-nav" as={NavLink} to="/videos">Videos</Nav.Link>
            </Navbar>

            <Container fluid>
              <Row className="thomas-headshot-background">
                <Col>
                </Col>
                </Row>
            </Container>
        </div>
    )}
}

export default MainNav;